import { Controller } from "stimulus"
import Chart from 'chart.js/auto'

export default class extends Controller {
  initialize() {
    this.setDefaults()
    this.setLegends()
  }

  setLegends() {
    let legends = document.querySelectorAll('[data-toggle="legend"]')

    legends.forEach(function(item) {
      const chart = Chart.getChart(item),
            div = document.createElement("div")

      chart.legend.legendItems?.forEach((legend => {
        div.innerHTML += `<span class="chart-legend-item"><span class="chart-legend-color" style="background-color: ${legend.fillStyle}"></span>${legend.text}</span>`
      }))

      document.querySelector(item.dataset.target).appendChild(div)
    })
  }

  setDefaults() {
    Chart.defaults.responsive = true;
    Chart.defaults.maintainAspectRatio = false;
    Chart.defaults.color = "#95aac9";
    Chart.defaults.font.family = 'Inter';
    Chart.defaults.font.size = 13;
    Chart.defaults.layout.padding = 0;
    Chart.defaults.plugins.legend.display = false;
    Chart.defaults.elements.point.radius = 3.5;
    Chart.defaults.elements.point.backgroundColor = "#3d569c";
    Chart.defaults.elements.line.tension = .1;
    Chart.defaults.elements.line.borderWidth = 3.5;
    Chart.defaults.elements.line.borderColor = "#3d569c";
    Chart.defaults.elements.line.backgroundColor = "transparent";
    Chart.defaults.elements.line.borderCapStyle = "rounded";
    Chart.defaults.elements.bar.backgroundColor = "#3d569c";
    Chart.defaults.elements.bar.borderWidth = 0;
    Chart.defaults.elements.bar.borderRadius = 5;
    Chart.defaults.elements.bar.borderSkipped = false;
    Chart.defaults.datasets.bar.maxBarThickness = 8;
    Chart.defaults.elements.arc.backgroundColor = "#3d569c",
    Chart.defaults.elements.arc.borderColor = "#fff";
    Chart.defaults.elements.arc.borderWidth = 4;
    Chart.defaults.elements.arc.hoverBorderColor = "#fff";
    Chart.defaults.plugins.tooltip.enabled = false;
    Chart.defaults.plugins.tooltip.mode = "index";
    Chart.defaults.plugins.tooltip.intersect = false;
    Chart.defaults.plugins.tooltip.external = this.tooltip;
    Chart.defaults.plugins.tooltip.callbacks.label = this.label;
    Chart.defaults.datasets.doughnut.cutout = "75%";
    Chart.defaults.scales.linear.grid = {
      borderDash: [10],
      color: "#e3ebf6",
      drawBorder: false,
      drawTicks: false
    };
    Chart.defaults.scales.linear.beginAtZero = true;
    Chart.defaults.scales.linear.ticks.padding = 10;
    Chart.defaults.scales.linear.ticks.stepSize = 10;
    Chart.defaults.scales.category.grid = {
      drawBorder: false,
      drawOnChartArea: false,
      drawTicks: false
    };
    Chart.defaults.scales.category.ticks.padding = 20;

    Chart.overrides.doughnut.plugins.tooltip.callbacks.title = function(e) {
      return e[0].label
    }
    Chart.overrides.doughnut.plugins.tooltip.callbacks.label = function(e) {
      const callback = e.chart.options.plugins.tooltip.callbacks,
            before = callback.beforeLabel() || "",
            after = callback.afterLabel() || "";
      return before + e.formattedValue + after
    }
  }

  label(e) {
    const scale = e.chart.scales[e.dataset.yAxisID || "y"];
    return (e.chart.tooltip.dataPoints.length > 1 ? " " + e.dataset.label + " " : " ") + scale.options.ticks.callback.apply(scale, [e.parsed.y, 0, []])
  }

  tooltip(e) {
    const {
      chart,
      tooltip
    } = e;

    let custom_tooltip = function(chart) {
      let custom = chart.canvas.parentNode.querySelector("div");

      if (!custom) {
        custom = document.createElement("div");
        custom.setAttribute("id", "custom-tooltip");
        custom.setAttribute("role", "tooltip");
        custom.classList.add("popover", "bs-popover-top");

        const arrow = document.createElement("div"),
              container = document.createElement("div");

        arrow.classList.add("popover-arrow", "translate-middle-x");
        container.classList.add("popover-container")

        custom.appendChild(arrow)
        custom.appendChild(container)
        chart.canvas.parentNode.appendChild(custom)
      }

      return custom
    }(chart);

    if (tooltip.opacity === 0) {
      return custom_tooltip.style.visibility = "hidden";
    }

    if (tooltip.body) {
      const title = tooltip.title || [],
            body_lines = tooltip.body.map((e => e.lines)),
            container_title = document.createElement("div"),
            container_body = document.createElement("div");

      title.forEach((e => {
        const header = document.createElement("h3");
        header.classList.add("popover-header", "text-center", "text-nowrap");
        const text = document.createTextNode(e);
        header.appendChild(text)
        container_title.appendChild(header)
      }));

      body_lines.forEach((e, index) => {
        const label_color = tooltip.labelColors[index],
              color = document.createElement("span"),
              body = document.createElement("div");

        color.classList.add("popover-body-color")
        color.style.backgroundColor = chart.config.type === "line" && label_color.borderColor !== "rgba(0,0,0,0.1)" ? label_color.borderColor : label_color.backgroundColor;
        
        body.classList.add("popover-body", "d-flex", "align-items-center", "text-nowrap"), 
        body.classList.add(body.length > 1 ? "justify-content-left" : "justify-content-center");
        const text = document.createTextNode(e);
        body.appendChild(color)
        body.appendChild(text)
        container_body.appendChild(body)
      });

      const container = custom_tooltip.querySelector(".popover-container");
      for (; container.firstChild;) container.firstChild.remove();
      container.appendChild(container_title)
      container.appendChild(container_body)
    }

    const {
      offsetLeft,
      offsetTop
    } = chart.canvas;

    custom_tooltip.style.visibility = "visible"
    custom_tooltip.style.left = offsetLeft + tooltip.caretX + "px"
    custom_tooltip.style.top = offsetTop + tooltip.caretY + "px"
    custom_tooltip.style.transform = "translateX(-50%) translateY(-100%) translateY(-1rem)"
  }
}
