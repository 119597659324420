import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "autosubmitUserId", "btnSubmit" ]

  initialize() {
    if (this.autosubmitUserIdTarget.value) {
      // this.element.requestSubmit()
      this.btnSubmitTarget.click()
    }
  }
}
