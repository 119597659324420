import { Controller } from "stimulus"
import { Chart } from 'chart.js'

export default class extends Controller {
  static values = {
    labels: Array,
    data: Array
  }

  initialize() {
    this.drawChart()
  }

  drawChart() {
    this.chart = new Chart(this.element, {
      type: 'bar',
      options: {
        scales: {
          y: {
            ticks: {
              stepSize: 5,
              callback: function(value) {
                return value + ' vistas';
              }
            }
          }
        }
      },
      data: {
        labels: this.labelsValue,
        datasets: [{
          label: 'Vistas',
          data: this.dataValue
        }]
      }
    })
  }
}
