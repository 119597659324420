import { Controller } from "stimulus"
import DateRangePicker from "daterangepicker"
import moment from 'moment'

export default class extends Controller {
  static values = {
    startDate: String,
    endDate: String,
    url: String
  }

  initialize() {
    moment.locale('es')
    this.setDatePicker()
  }

  setDatePicker() {
    let startDate = moment(this.startDateValue)
    let endDate = moment(this.endDateValue)

    this.datepicker = new DateRangePicker(this.element, {
      startDate: startDate,
      endDate: endDate,
      maxDate: moment(),
      opens: 'left',
      applyButtonClasses: "btn-primary",
      ranges: {
        'Últimos 7 días': [moment().subtract(6, 'days'), moment()],
        'Últimos 30 días': [moment().subtract(29, 'days'), moment()],
        'Mes anterior': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      },
      locale: {
        cancelLabel: "Cerrar",
        applyLabel: "Aceptar",
        customRangeLabel: "Rango"
      }
    }, this.callback.bind(this))
  }

  callback(start, end) {
    window.location = `${this.urlValue}?start_date=${start.format('YYYY-MM-DD')}&end_date=${end.format('YYYY-MM-DD')}`
  }
}
