import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["ios", "android"]

  initialize() {
    const os = this.getMobileOS()

    if (os == "Android") {
      this.iosTarget.style.display = 'none'
    } else if (os == "iOS") {
      this.androidTarget.style.display = 'none'
    } else {
      this.iosTarget.style.display = 'none'
      this.androidTarget.style.display = 'none'
    }
  }

  getMobileOS() {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
      return "Android"
    }
    else if (/iPad|iPhone|iPod/.test(ua) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      return "iOS"
    }
    return "Other"
  }
}
