import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "iconOpen", "iconClose" ]

  toggle(e) {
    e.preventDefault()

    if (this.inputTarget.type === "password") {
      this.inputTarget.type = "text"
      this.iconOpenTarget.style.display = 'none'
      this.iconCloseTarget.style.display = 'block'
    } else {
      this.inputTarget.type = "password"
      this.iconOpenTarget.style.display = 'block'
      this.iconCloseTarget.style.display = 'none'
    }
  }
}
