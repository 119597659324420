import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['newCompanyName']

  initialize() {
    this.newCompanyNameTarget.style.display = 'none'
  }

  toggleField(e) {
    let option = e.currentTarget.options[e.currentTarget.selectedIndex]

    if (option.classList.contains('other')) {
      this.newCompanyNameTarget.style.display = 'block'
      this.newCompanyNameTarget.focus()
    } else {
      this.newCompanyNameTarget.style.display = 'none'
      this.newCompanyNameTarget.value = ''
    }
  }
}
