import { Controller } from "stimulus"
import { Chart } from 'chart.js'

export default class extends Controller {
  static targets = ['canvas', 'legends']

  initialize() {
    this.drawChart()
    this.drawLegends()
  }

  drawChart() {
    this.chart = new Chart(this.canvasTarget, {
      type: 'doughnut',
      options: {
        plugins: {
          tooltip: {
            callbacks: {
              afterLabel: function() {
                return '%'
              }
            }
          }
        }
      },
      data: {
        labels: ['Usando Sépalo', 'No Usando Sépalo'],
        datasets: [{
          data: [67, 33],
          backgroundColor: ['#263588', '#E5E5FF']
        }]
      }
    })
  }

  drawLegends() {
    let div = document.createElement("div")

    this.chart.legend.legendItems?.forEach((legend, index) => {
      div.innerHTML += `
        <span class="chart-legend-item">
          <span class="chart-legend-color" style="background-color: ${legend.fillStyle}"></span>
          ${legend.text} ${['(67%)', '(33%)'][index]}
        </span>`
    })

    this.legendsTarget.appendChild(div)
  }
}
